import request from '@/utils/request'


// 查询接单和服务者关联列表
export function listTenders(query) {
  return request({
    url: '/waiter/waiter-tenders/list',
    method: 'get',
    params: query
  })
}

// 查询接单和服务者关联分页
export function pageTenders(query) {
  return request({
    url: '/waiter/waiter-tenders/page',
    method: 'get',
    params: query
  })
}

// 查询接单和服务者关联详细
export function getTenders(data) {
  return request({
    url: '/waiter/waiter-tenders/detail',
    method: 'get',
    params: data
  })
}

// 新增接单和服务者关联
export function addTenders(data) {
  return request({
    url: '/waiter/waiter-tenders/add',
    method: 'post',
    data: data
  })
}

// 修改接单和服务者关联
export function updateTenders(data) {
  return request({
    url: '/waiter/waiter-tenders/edit',
    method: 'post',
    data: data
  })
}

// 删除接单和服务者关联
export function delTenders(data) {
  return request({
    url: '/waiter/waiter-tenders/delete',
    method: 'post',
    data: data
  })
}
