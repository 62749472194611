import request from '@/utils/request'


// 查询招标接单列表
export function listTenders(query) {
  return request({
    url: '/tenders/tenders/list',
    method: 'get',
    params: query
  })
}

// 查询招标接单分页
export function pageTenders(query) {
  return request({
    url: '/tenders/tenders/page',
    method: 'get',
    params: query
  })
}

// 查询招标接单详细
export function getTenders(data) {
  return request({
    url: '/tenders/tenders/detail',
    method: 'get',
    params: data
  })
}

// 新增招标接单
export function addTenders(data) {
  return request({
    url: '/tenders/tenders/add',
    method: 'post',
    data: data
  })
}

// 修改招标接单
export function updateTenders(data) {
  return request({
    url: '/tenders/tenders/edit',
    method: 'post',
    data: data
  })
}

// 删除招标接单
export function delTenders(data) {
  return request({
    url: '/tenders/tenders/delete',
    method: 'post',
    data: data
  })
}
