import request from '@/utils/request'


// 查询服务者列表
export function listWaiter(query) {
  return request({
    url: '/user/userWaiter/list',
    method: 'get',
    params: query
  })
}

// 查询服务者分页
export function pageWaiter(query) {
  return request({
    url: '/user/userWaiter/page',
    method: 'get',
    params: query
  })
}

// 查询服务者详细
export function getWaiter(data) {
  return request({
    url: '/user/userWaiter/detail',
    method: 'get',
    params: data
  })
}

// 新增服务者
export function addWaiter(data) {
  return request({
    url: '/user/userWaiter/add',
    method: 'post',
    data: data
  })
}

// 修改服务者
export function updateWaiter(data) {
  return request({
    url: '/user/userWaiter/edit',
    method: 'post',
    data: data
  })
}

// 删除服务者
export function delWaiter(data) {
  return request({
    url: '/user/userWaiter/delete',
    method: 'post',
    data: data
  })
}
