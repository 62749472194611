<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="金额" prop="money" >
        <a-input v-model="form.money" placeholder="请输入金额" />
      </a-form-model-item>
      <a-form-model-item label="标题" prop="title" >
        <a-input v-model="form.title" placeholder="请输入标题" />
      </a-form-model-item>
      <a-form-model-item label="封面图" prop="coverPicture" >
        <file-upload type="image" :defaultList="form.imgArr1" :count="1"
                     @input="getImg($event, 'coverPicture')"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="内容" prop="content" >
        <editor v-model="form.content" />
      </a-form-model-item>
      <a-form-model-item label="图片" prop="picture" >
        <file-upload type="image" :defaultList="form.imgArr" :count="1"
                     @input="getImg($event, 'picture')"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="招标开始时间" prop="startTime" >
        <a-date-picker style="width: 100%" v-model="form.startTime" format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="招标结束时间" prop="endTime" >
        <a-date-picker style="width: 100%" v-model="form.endTime" format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear />
      </a-form-model-item>

      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getTenders, addTenders, updateTenders } from '@/api/tenders/tenders'
import Editor from '@/components/Editor'
import {guid} from "@/utils/ruoyi";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        imgCount: 1,
        imgArr: [],
        imgArr1: [],
        money: null,

        title: null,

        coverPicture: null,

        content: null,

        picture: null,

        startTime: null,

        endTime: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        money: [
          { required: true, message: '金额不能为空', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
        coverPicture: [
          { required: true, message: '封面图不能为空', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
        startTime: [
          { required: true, message: '招标开始不能为空', trigger: 'blur' }
        ],
        endTime: [
          { required: true, message: '招标结束不能为空', trigger: 'blur' }
        ],
        picture: [
          { required: true, message: '图片不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        money: null,
        title: null,
        coverPicture: null,
        content: null,
        picture: null,
        startTime: null,
        endTime: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getTenders({"id":id}).then(response => {
        this.form = response.data

        let that = this;
        this.form.imgArr = []
        this.form.imgArr.push({
          uid: guid(8, 10),
          name: that.form.picture,
          status: 'done',
          halfUrl: that.form.picture,
          url: that.form.picture,
          path: that.form.picture
        })



        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateTenders(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addTenders(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    /******************** 上传图片数据处理 start ********************/
    /**前端 需要将图片格式处理成数组，方便数据回显 */
    setImgData(field, img) {
      let fieldImgArr = []
      if (img.indexOf(",") === -1) {
        // 单图
        if (this.form[`${field}Count`] === 1) {
          if (img) {
            fieldImgArr.push({
              uid: guid(8, 10),
              name: img,
              status: 'done',
              halfUrl: img,
              url: img,
              path: img
            })
          }
        }
      } else {
        // 多图
        if (this.form[`${field}Count`] > 1) {
          let imgArr = img.split(",")
          if (imgArr.length) {
            imgArr.map(item => {
              fieldImgArr.push({
                uid: guid(8, 10),
                name: img,
                status: 'done',
                halfUrl: img,
                url: item,
                path: item
              })
            })
          }
        }
      }
      return fieldImgArr
    },
    /**上传图片成功回调 */
    getImg(info, fieldName) {
      console.log('info', info)
      if (info.length === 0) {
        this.form[fieldName] = ''
        this.form[`${fieldName}Arr`] = []
      } else {
        if (this.form[`${fieldName}Count`] === 1) {
          this.form[fieldName] = info[0].url
          this.form[`${fieldName}Arr`] = info
        } else {
          this.form[fieldName] = info.map(ele => { return ele.url }).join(",")
          this.form[`${fieldName}Arr`] = info
        }
      }
    },
    /** 点击视频 弹窗打窗口播放视频*/
    async handlePreview(file) {
      this.previewImage = this.form.video || file.video;
      this.previewVisible = true;
      this.$nextTick(() => {
        this.$refs.myVideo.play()
      })
    },
    handleCancel() {
      this.previewVisible = false;
    },
    /******************** 上传图片数据处理 end   ********************/



  }
}
</script>
