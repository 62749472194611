<template>
  <div>
    <a-modal
      title="分配服务者"
      width="600px"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :maskClosable="false"
    >
      <div>
        <h3><span style="font-weight: 600">招标名称：</span>{{ data.title }}</h3>
        <div style="display: flex; margin-top: 14px">
          <h3><span style="font-weight: 600">服务者：</span></h3>
          <a-select
            style="width: 80%"
            @change="handleChange"
            mode="multiple"
            v-model="idList"
            option-filter-prop="children"
            :filter-option="filterOption"
          >
            <a-select-option v-for="(item, index) in list" :key="index" :value="item.userName">
              <span>{{ item.userName }}</span>
            </a-select-option>
          </a-select>
        </div>
        <!-- {{ list }} -->
      </div>
    </a-modal>
  </div>
</template>

<script>
import { addTenders } from '@/api/user/tenders'
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      idList: [],
      id: [],
    }
  },
  created() {
    this.getlistWaiter()
  },
  methods: {
    showModal() {
      this.visible = true
    },
    handleOk() {
      let newArr = []
      this.id.forEach((item) => {
        newArr.push({
          waiterId: item.id,
          tendersId: this.data.id,
        })
      })
      // console.log(newArr)

      addTenders(newArr).then((res) => {
        //    console.log(res);
        if (res.message == '成功.') {
          this.visible = false
          this.$message.success('提交成功')
        } else {
          this.$message.error('提交失败')
        }
      })
    },
    handleCancel(e) {
      this.visible = false
    },
    handleChange(value) {
      let arr = []
      value.forEach((item) => {
        this.list.map((item1, index) => {
          if (item == index) {
            arr.push(item1)
          }
        })
      })
      this.id = arr
    },
    filterOption(input, option) {
      console.log(input , option);
      return option.componentOptions.propsData.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
  },
}
</script>

<style lang="less" scoped>
</style>