<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <!--            <a-col :md="8" :sm="24">-->
            <!--              <a-form-item label="金额" prop="money">-->
            <!--                <a-input v-model="queryParam.money" placeholder="请输入金额" allow-clear/>-->
            <!--              </a-form-item>-->
            <!--            </a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item label="标题" prop="title">
                <a-input v-model="queryParam.title" placeholder="请输入标题" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="封面图" prop="coverPicture">-->
              <!--                  <a-input v-model="queryParam.coverPicture" placeholder="请输入封面图" allow-clear/>-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="图片" prop="picture">-->
              <!--                  <a-input v-model="queryParam.picture" placeholder="请输入图片" allow-clear/>-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenders:tenders:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['tenders:tenders:edit']"
        >
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['tenders:tenders:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['tenders:tenders:export']">-->
        <!--          <a-icon type="download" />导出-->
        <!--        </a-button>-->
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>

        <span slot="coverPicture" slot-scope="text, record">
          <img
            v-viewer
            style="width: 48px; height: 48px; margin-top: 5px; margin-right: 5px"
            v-if="record.coverPicture"
            :src="record.coverPicture"
            preview="封面图"
          />
        </span>
        <span slot="picture" slot-scope="text, record">
          <img
            v-viewer
            style="width: 48px; height: 48px; margin-top: 5px; margin-right: 5px"
            v-if="record.picture"
            :src="record.picture"
            preview="图片"
          />
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenders:tenders:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenders:tenders:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenders:tenders:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenders:tenders:remove']"> <a-icon type="delete" />删除 </a>
          <a-divider type="vertical" v-hasPermi="['tenders:tenders:remove']" />
          <a @click="handleAllocation(record)" v-hasPermi="['tenders:tenders:remove']">
            <a-icon type="highlight" />分配服务者
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />

      <!--  分配 弹出框 -->
      <allocation-model ref="allocationModelRefs" :data="rowData" :list="fuwuList"/>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageTenders, listTenders, delTenders } from '@/api/tenders/tenders'
import { listWaiter } from '@/api/user/waiter'

import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import AllocationModel from './modules/Allocation-model.vue'
export default {
  name: 'Tenders',
  components: {
    CreateForm,
    AllocationModel,
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        money: null,
        title: null,
        coverPicture: null,
        content: null,
        picture: null,
        startTime: null,
        endTime: null,
        pageNum: 1,
        pageSize: 10,
      },
      columns: [
        {
          title: '编号',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '金额',
          dataIndex: 'money',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '标题',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '封面图',
          dataIndex: 'coverPicture',
          scopedSlots: { customRender: 'coverPicture' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: '内容',
          dataIndex: 'content',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '图片',
          dataIndex: 'picture',
          scopedSlots: { customRender: 'picture' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
      ],
      rowData: {},
      fuwuList:[]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询招标接单列表 */
    getList() {
      this.loading = true
      pageTenders(this.queryParam).then((response) => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        money: undefined,
        title: undefined,
        coverPicture: undefined,
        content: undefined,
        picture: undefined,
        startTime: undefined,
        endTime: undefined,
        pageNum: 1,
        pageSize: 10,
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map((id) => data.push({ id: id }))
          return delTenders(data).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        },
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download(
            'tenders/tenders/export',
            {
              ...that.queryParam,
            },
            `招标接单_${new Date().getTime()}.xlsx`
          )
        },
        onCancel() {},
      })
    },
    //获取服务这列表
    getlistWaiter() {
      listWaiter().then((res) => {
        // console.log('服务者', res)
        this.fuwuList = res.data
      })
    },
    // 打开分配弹框
    handleAllocation(row) {
      this.rowData = row
      this.getlistWaiter()
      this.$refs.allocationModelRefs.showModal()
    },
  },
}
</script>
